import { render, staticRenderFns } from "./PageArbitral.vue?vue&type=template&id=43463adc&scoped=true"
import script from "./PageArbitral.vue?vue&type=script&lang=js"
export * from "./PageArbitral.vue?vue&type=script&lang=js"
import style0 from "./PageArbitral.vue?vue&type=style&index=0&id=43463adc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43463adc",
  null
  
)

export default component.exports